<!-- ----------------------------------------- template ----------------------------------------- -->
<template>
  <el-dialog
    :visible.sync="showSessionGroupDialog"
    :title="sessionGroupInfo.id == '' ? '添加日程组' : '编辑日程组'"
  >
    <div>
      <el-form :model="sessionGroupInfo" :rules="rules" ref="sessionGroupForm">
        <el-form-item label="日程组" prop="name">
          <el-input
            v-model="sessionGroupInfo.name"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="日程组日期" prop="date">
          <el-select v-model="sessionGroupInfo.date" placeholder="请选择">
            <el-option
              v-for="item in dateRange"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开始时间" prop="start_time">
          <el-time-picker
            v-model="sessionGroupInfo.start_time"
            format="HH:mm"
            value-format="HH:mm"
            :default-value="dafaultValue"
            placeholder="请选择时间"
          ></el-time-picker>
        </el-form-item>
        <el-form-item label="日程组时长" prop="duration">
          <el-input-number
            v-model="sessionGroupInfo.duration"
            placeholder="请输入"
          ></el-input-number
          >（单位分钟）
        </el-form-item>
      </el-form>
      <div style="text-align: right">
        <span slot="footer" class="dialog-footer">
          <el-button @click="showSessionGroupDialog = false">取 消</el-button>
          <el-button type="primary" @click="saveSessionGroup()"
            >确 定</el-button
          >
        </span>
      </div>
    </div>
  </el-dialog>
</template>

<!-- ------------------------------------------ script ------------------------------------------ -->
<script>
import {
    getMeetingInfo,
    getSessionGroups,
    removeSessionGroup,
    updateSessionGroup
} from '../../../api/api'
import { getAllDatesInRange } from '@/utils/date'
import dayjs from "dayjs";
export default {
  name: "",
  components: {},
  props: {
    meetingId: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      dafaultValue: undefined,
      showSessionGroupDialog: false,
      sessionGroupInfo: {},
      dateRange: [], // 开始日期范围

      rules: {
        name: [{ required: true, message: "Please input", trigger: "blur" }],
        date: [{ required: true, message: "Please input", trigger: "blur" }],
        start_time: [
          { required: true, message: "Please input", trigger: "blur" },
        ],
        duration: [
          { required: true, message: "Please input", trigger: "blur" },
        ],
      },
    };
  },

  /* 一.生命周期函数 */
  created() {
    this.requestMeetingInfo()
  },

  /* 二.监控函数 */
  watch: {},

  computed: {},

  /* 三.内部功能函数 */
  methods: {
    /* ----------------------事件调用函数------------------------ */
    // 事件调用函数注释
    actionXxx() {},

    /* ----------------------内部功能函数------------------------ */
    // 内部功能函数注释
    show(row) {
      if (row) {
        this.sessionGroupInfo = {
          id: row._id.$id,
          meeting_id: row.meeting_id,
          name: row.name,
          date: dayjs(row.date * 1000).format("YYYY-MM-DD"),
          start_time: row.start_time,
          duration: row.duration,
        };
      } else {
        this.sessionGroupInfo = {
          id: "",
          meeting_id: this.meetingId,
          name: "",
          date: this.dateRange[0],
          start_time: "",
          duration: "",
        };
      }
      this.showSessionGroupDialog = true;
    },


    saveSessionGroup() {
            this.$refs.sessionGroupForm.validate((val) => {
                if (val) {
                    const formData = {
                        ...this.sessionGroupInfo,
                        date: dayjs(this.sessionGroupInfo.date).valueOf() / 1000
                    }
                    updateSessionGroup(formData).then((res) => {
                        this.$message.success('保存成功')
                        this.$emit('on-complete', res.data.data._id.$id)
                        this.showSessionGroupDialog = false
                    })
                }
            })
        },
        requestMeetingInfo() {
            getMeetingInfo(this.meetingId).then((res) => {
                const { start_date, end_date } = res.data.data
                this.dateRange = getAllDatesInRange(start_date * 1000, end_date * 1000)
            })
        },
    /* ----------------------服务请求函数------------------------ */
    // 服务请求函数注释
    ajaxXxx() {},
  },
};
</script>

<!-- ------------------------------------------ style ------------------------------------------ -->
<style scoped lang="scss"></style>
